<template>
    <v-container v-if="permissions('accessReports')">

    </v-container>
    <denied-access v-else/>
</template>

<script>
import permissions from '../mixins/permissions'
import DeniedAccess from './denied-access.vue';
export default {
    components: {
        'denied-access':DeniedAccess,
    }, 
    mixins: [permissions],
}
</script>

<style>

</style>