<template>
    <v-container class="py-4" v-if="permissions('accessBreakingNews')">
        <!-- Header -->
        <v-toolbar dark flat class="mb-0 mt-0 px-6">
            <v-toolbar-title>Notas Destacadas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="permissions('editBreakingNews')" @click="breaking_news=[]" class="ml-8" text>
                Limpiar
            </v-btn> 
            <v-btn v-if="permissions('editBreakingNews')" :light="isDiferent || loaderSave" @click="save()" class="ml-8 elevation-0" :disabled="!isDiferent || loaderSave || breaking_news.length<7" :loading="loaderSave">
                Guardar
            </v-btn> 
        </v-toolbar>
        <draggable v-if="breaking_news!=undefined && breaking_news.length>0" style="height:230px!important; background:white" :list="breaking_news" class="list-group mb-4 elevation-1" draggable=".item" group="a">
            <v-card :disabled="!permissions('editBreakingNews')" style="width:calc(86% / 7); display:inline-block; margin:1%;" class="list-group-item item" v-for="noticia in breaking_news" :key="noticia.featured_position">
                <div :style="'background-image:url(' + noticia.featured_media_path + '); height:130px; width:100%;  background-size: cover; background-position: center;'">
                    <v-btn x-small dark rounded @click="removeNew(noticia)">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-title style="font-size:15px; line-height:20px; display: block;" class="px-4 py-2">{{noticia.id}} - {{noticia.title.slice(0, 30)}}<span v-if="noticia.title.length>30">...</span></v-card-title>
            </v-card>
        </draggable>

        
        
        <!-- Tabla -->
        <v-data-table
        aria-disabled
        v-model="breaking_news"
        :show-select="permissions('editBreakingNews')"
        class="mb-6 elevation-1"
        :items="records" 
        :headers="header"
        :footer-props="{'items-per-page-options':[15, 30, 50, totalRecords]}"
        :options.sync="options"
        :server-items-length="totalRecords"
        :loading="loader">
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de noticias aún
            </template>
            <template v-slot:[`item.date`]="{ item }">
                {{dateFormat(item.created_at)}}
            </template>
            <template v-slot:[`item.categories`]="{ item }">
                <span v-for="(category, index) in item.categories" v-bind:key="index">{{category.name}} <span v-if="index<(item.categories.length-1)">, </span></span>
            </template>
        </v-data-table>

        <!-- errores -->
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
    <denied-access v-else/>
</template>

<script>
import permissions from '../../mixins/permissions'
import DeniedAccess from '../denied-access.vue';
import draggable from 'vuedraggable'
import axios from "axios";
export default {
    mixins: [permissions],
    components: {
        draggable,
        'denied-access':DeniedAccess,
    },
    data: () => ({
        vueltas:0,
        loaderSave:false,
        breaking_news:[],
        filters:false,
        record:{},
        loading: true,
        options: {},
        sheet: false,
        snackbar: {
            show: false,
            message: null,
            color: null
        },
    }),
    computed:{
        isDiferent(){
            if(JSON.stringify(this.breaking_newsOld) != JSON.stringify(this.breaking_news)){
                return true
            }
        },
        breaking_newsOld(){
            return this.$store.state.post.breaking_newsOld
        },
        loader(){
            return this.$store.state.post.loader
        },
        totalRecords(){
            return this.$store.state.post.posts_total
        },
        records(){
            return this.$store.state.post.posts
        },
        header(){ return [
            { text: 'Titulo', value: 'title'},
            { text: 'Categorías', value: 'categories'},
            { text: 'Fecha', value: 'date'},
            { value: 'actions', sortable: false, align: 'end', }
        ]},
        currentUser:{
            get(){
                return this.$store.state.currentUser.user
            }
        },
    },
    watch: {
        breaking_news (val, oldVal) {
            this.vueltas = this.vueltas + 1
            if(this.vueltas==2){
                this.breaking_news = JSON.parse(JSON.stringify(this.breaking_news)).sort((a, b) => a.featured_position - b.featured_position)
            }
            if(val.length == this.options.itemsPerPage) {
                this.$nextTick(() => {
                    this.breaking_news = []  
                })
            }
            else if(val.length > 7) {
                this.$nextTick(() => {
                    this.breaking_news = oldVal  
                })
            }
        },
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods:{
        save(){
            var breaking_news = this.breaking_news.map((noticia, index)=>{return{
                id:noticia.id,
                position:index
            }})
            this.loaderSave = true
            this.$nextTick(() => {
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/news/sync_featured', breaking_news).then(response=>{
                    this.loaderSave = false
                    this.snackbar = {
                        message: 'Cambio realizado con éxito',
                        color: 'success',
                        show: true
                    }
                }).catch(error=>{
                    this.loaderSave = false
                    this.snackbar = {
                        message: 'No se pudo realizar el cambio',
                        color: 'error',
                        show: true
                    }
                })
            })
        },
        removeNew(noticia){
            var index = this.breaking_news.indexOf(noticia)
            this.breaking_news.splice(index, 1);
        },
        dateFormat(date){
            // Creamos el objeto fecha instanciándolo con la clase Date
            const fecha = new Date(date.slice(0,10) + ' 00:00:00');
            // Creamos array con los días de la semana
            const dias_semana = ['Domingo', 'Lunes', 'martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            //Creamos constante para el dia de hoy
            const hoy = new Date(new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).slice(0,10))
            //sacamos diferencia
            const difference = (Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()) - Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()))/(1000*60*60*24)
            // Creamos array con los meses del año
            const meses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
            // Construimos el formato de salida
            if(fecha.getUTCFullYear()!=new Date().getUTCFullYear()){
                return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()];
            }else{
                return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()] + ' de ' + fecha.getUTCFullYear();
            }
            
        },
        getDataFromApi () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            if(this.category_filter!=''){
                this.category_filter = '&categories='+this.category_filter
            }
            var sort = ''
            if (sortBy.length === 1 && sortDesc.length === 1) {
                if(sortDesc[0]){
                    sort = "-" + sortBy[0]
                }else{
                    sort = sortBy[0]
                }
            }
            this.$store.dispatch('post/getPosts', {'sort':sort, 'page':page, 'itemsPerPage':itemsPerPage, 'category':this.category_filter}).then(data => {
                this.breaking_news = this.breaking_newsOld
                this.filterStorageLength = localStorage.getItem('filtersPostsLength')
            })
        },
    },
}
</script>