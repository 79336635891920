
import axios from "axios";
const state = {
    posts:[],
    show:false,
    posts_total:0,
    loader:true,
    breaking_newsOld:[]
};
const getters = {};

const actions = {
    getPosts( {commit, state}, props ){
        state.loader = true
        var items_per_page = props.itemsPerPage
        if(props.sort!=undefined){
            var sort_by = "&sort=" + props.sort
        }
        var page = props.page
        var category = props.category
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/posts?page=' + page + "&itemsPerPage=" + items_per_page + category + sort_by).then(response => {
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/posts?filter[is_featured]=1&sort=featured_position').then(response2 => {
                    commit('setBrekingNews', response2.data.data)
                    commit('setPosts', response.data.data);
                    state.posts_total = response.data.meta.total
                    state.loader = false;
                }).finally(() => (resolve(false)))  
            })
        })
    },
};

const mutations = {
    setPosts(state, data){
        state.posts = data;
    },
    setShow(){
        state.show = true;
    },
    setBrekingNews(state, data){
        state.breaking_newsOld = Object.freeze(data)
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}