<template>
    <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Reporte Anuncio - <strong>{{ad.title}}</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="downloadPDF()">
              Descargar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="mx-8 pb-12" >
            <div ref="sharedContent">
                <v-row class="my-0 mx-4 px-12 pt-4" style="background:white;">
                    <v-menu offset-y :close-on-content-click="closeDatePicker(date)">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field style="max-width:280px;" class="mt-5" outlined dense clearable v-model="date" label="Periodo" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" range></v-date-picker>
                    </v-menu>
                </v-row>
                <v-row class="ma-0 px-12 mb-6">
                    <v-col cols="4">
                        <v-card color="#7a63f5e6" dark class="elevation-0">
                            <v-card-subtitle style="font-size:16px;" class="pb-0">Impresiones</v-card-subtitle>
                            <v-card-title style="font-size:30px;" class="pt-0">{{totals.impressions}}</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card color="#4d30f2" dark class="elevation-0">
                            <v-card-subtitle style="font-size:16px;" class="pb-0">Clics</v-card-subtitle>
                            <v-card-title style="font-size:30px;" class="pt-0">{{totals.clicks}}</v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card color="#faab00" dark class="elevation-0">
                            <v-card-subtitle style="font-size:16px;" class="pb-0">Conversiones</v-card-subtitle>
                            <v-card-title style="font-size:30px;" class="pt-0">{{totals.conversions}}%</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <apexchart v-if="!loading" type="line" height="550" :options="chartOptions" :series="series"></apexchart>
                
                <v-data-table
                    class="mt-6 mx-12 elevation-0"
                    :items="table" 
                    :headers="header"
                    :loading="loading"
                    hide-default-footer
                    :items-per-page="table.length"
                >
                <template v-slot:[`item.conversions`]="{ item }">
                    {{((100/item.impressions)*item.clicks).toFixed(2)}}%
                </template>
                </v-data-table>
            </div>
        </div>

        
        
        <VueHtml2pdf 
            :filename="'Reporte anuncio ' + ad.title + ' periodo ' + date" 
            :manual-pagination="true" 
            :enable-download="true" 
            ref="DownloadComp">
            <section slot="pdf-content">
                <div class="invoice-box" style="background-color:white; padding:30px!important;" id="divName" elevation="0">
                    <v-row class="ma-0">
                        <v-spacer/>
                        <img style="height:80px; padding:20px;" src="logo-report.png"/>
                        <v-spacer/>
                    </v-row>
                    <v-row style="background:white; margin:20px 0px;">
                        <!--img style="max-height:100px; max-width:100px;" :src="ad.image_url"/-->
                        <div>
                            <v-card-title>
                                Reporte Anuncio - <strong style="margin-left:8px;">{{ad.title}}</strong>
                            </v-card-title>
                            <v-card-subtitle v-if="date!=undefined && date.length==2">
                                {{'Periodo del ' + dateFormat(date[0]) + ' al ' + dateFormat(date[1])}} 
                            </v-card-subtitle>
                        </div>
                    </v-row>

                    <v-row class="ma-0 px-12 mb-6">
                        <v-col cols="4">
                            <v-card color="#7a63f5e6" dark class="elevation-0">
                                <v-card-subtitle style="font-size:16px; padding-bottom:0px;">Impresiones</v-card-subtitle>
                                <v-card-title style="font-size:30px;" class="pt-0">{{totals.impressions}}</v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card color="#4d30f2" dark class="elevation-0">
                                <v-card-subtitle style="font-size:16px; padding-bottom:0px;">Clics</v-card-subtitle>
                                <v-card-title style="font-size:30px;" class="pt-0">{{totals.clicks}}</v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card color="#faab00" dark class="elevation-0">
                                <v-card-subtitle style="font-size:16px; padding-bottom:0px;">Conversiones</v-card-subtitle>
                                <v-card-title style="font-size:30px;" class="pt-0">{{totals.conversions}}%</v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                    <apexchart v-if="!loading" type="line" height="450" style="margin-bottom:20px;" :options="chartOptions" :series="series"></apexchart>
                    
                    <v-data-table
                        class="mt-6 mx-12 elevation-0"
                        :items="table" 
                        :headers="header"
                        :loading="loading"
                        hide-default-footer
                        :items-per-page="table.length"
                    >
                    <template v-slot:[`item.conversions`]="{ item }">
                        {{((100/item.impressions)*item.clicks).toFixed(2)}}%
                    </template>
                    </v-data-table>
                </div>
            </section>
        </VueHtml2pdf>
    </v-card>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
    props:{
        ad:Object
    },
    data(){
        return{
            header:[
                { text: 'Fecha', value: 'day'},
                { text: 'Clics', value: 'clicks'},
                { text: 'Impresiones', value: 'impressions'},
                { text: 'Conversiones', value: 'conversions'},
            ],
            date:[],
            chartOptions: {
                colors: ['#7a63f5e6', '#4d30f2', '#faab00'],
                chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                },
                stroke: {
                    width: [0, 2, 5],//
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                markers: {
                    size: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            return y.toFixed(2)
                        }
                    }
                },
                xaxis: {
                    type: 'string'
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return //val.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
                        },
                        style: {
                            fontSize: '13px',
                            fontWeight: 900,
                        },
                        
                    },
                },
                
            }
        }
    },
    components: {
        VueHtml2pdf
    },
    computed:{
        series(){
            this.chartOptions.labels = this.$store.state.ads.labels
            return this.$store.state.ads.ad_report
        },
        totals(){
            return this.$store.state.ads.totals
        },
        loading(){
            return this.$store.state.ads.loading
        },
        table(){
            return this.$store.state.ads.table
        }
    },
    created(){
        this.date[0] = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
        this.date[1] = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
        this.getDataFromApi()
    },
    watch:{
        ad:{
            handler(){
                this.date[0] = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
                this.date[1] = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)
                this.getDataFromApi()
            }, deep:true
        }
    },
    methods:{
        dateFormat(date){
            // Creamos el objeto fecha instanciándolo con la clase Date
            const fecha = new Date(date.slice(0,10) + ' 00:00:00');
            // Creamos array con los días de la semana
            const dias_semana = ['Domingo', 'Lunes', 'martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            //Creamos constante para el dia de hoy
            const hoy = new Date(new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).slice(0,10))
            //sacamos diferencia
            const difference = (Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()) - Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()))/(1000*60*60*24)
            // Creamos array con los meses del año
            const meses = ['enero', 'febero', 'maro', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            // Construimos el formato de salida
            if(fecha.getUTCFullYear()!=new Date().getUTCFullYear()){
                return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()];
            }else{
                return dias_semana[fecha.getDay()] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()] + ' de ' + fecha.getUTCFullYear();
            }
        },
        closeDatePicker(dates){
            if(dates!=undefined && dates.length==2){
                return true
            }else{
                return false
            }
        },
        getDataFromApi(){
            this.$store.dispatch('ads/getAdReport', {filters:'?filter[ad_id]=' + this.ad.id + '&filter[date_between]=' + this.date})
        },
        close(){
            this.$emit("closeReportDialog", false);
        },
        downloadPDF () {
            this.$refs.DownloadComp.generatePdf()
        },
    }
}
</script>

<style>
.apexcharts-toolbar{
    display: none!important;
}
tspan, .v-data-table-header span, td{
    font-size:14px!important;
}
.apexcharts-legend-text{
    font-size:15px!important;
    padding-left: 20px!important;
    margin-right: 20px!important;
}
.v-data-table{
    border: solid 1px #e0e0e0!important;
}
.divName{
    font-family: "Roboto", sans-serif!important;
}
.invoice-box {
  max-width: 800px;
  margin: auto;
  /*padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);*/
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
</style>