import axios from "axios";
const state = {
    ads:[],
    ads_total:0,
    ad_report:[],
    labels:undefined,
    loading:true,
    totals:{},
    table:[]
};
const getters = {};

const actions = {
    getAds({commit}, values){
        axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/ads?page=" + values.page + values.filters).then(response => {
            commit('setAds', response.data.data);
            commit('setAdsTotal', response.data.meta.total);
        });
    },
    getAdReport({commit, state}, values){
        state.true = false
        axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/ad/report" + values.filters).then(response => {
            commit('setAdReport', response.data.data);
        });
    },
};

const mutations = {
    setAds(state, data){
        state.ads = data;
    },
    setAdReport(state, data){

        var report = data.map(item=>{
            const key = Object.keys(item)[0];
            const value = item[key];
            return {
                ...value,
                day: key
            };
        })
        
        state.table = report
        
        state.ad_report = [{
            name: 'Impresiones',
            type: 'column',
            data: report.map(resp=>resp.impressions),
        }, {
            name: 'Clics',//peach_amount
            type: 'area',
            data: report.map(resp=>resp.clicks),
        }, {
            name: 'Conversiones',//influencer_amount
            type: 'line',
            data: report.map(resp=>(100/resp.impressions)*resp.clicks)//.toFixed(2),
        }]

        let sumaImpresiones = 0;
        let sumaClics = 0;

        state.ad_report.forEach(metric => {
            if (metric.name === "Impresiones") {
                sumaImpresiones = metric.data.reduce((a, b) => a + b, sumaImpresiones);
            } else if (metric.name === "Clics") {
                sumaClics = metric.data.reduce((a, b) => a + b, sumaClics);
            }
        });

        state.totals = {
            clicks:sumaClics,
            impressions:sumaImpresiones,
            conversions:((100/sumaImpresiones)*sumaClics).toFixed(2)
        }

        state.labels = report.map(label=>label.day);
        state.loading = false
    },
    setAdsTotal(state, data){
        state.ads_total = data;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}