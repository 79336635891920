<template>
    <v-row class="ma-0 py-12 my-12">
        <v-spacer/>
        <v-card width="600px;" class="pa-6">
            <v-row class="ma-0">
                <v-icon x-large>mdi-account-off</v-icon>
                <div>
                    <v-card-title>Ups! no tienes permiso para acceder a este modulo</v-card-title>    
                    <v-card-subtitle>Contacta a tu superior para solicitar acceso</v-card-subtitle>  
                </div>
            </v-row>
        </v-card>  
        <v-spacer/>
    </v-row>
</template>

<script>
export default {

}
</script>

<style>

</style>